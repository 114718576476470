import apolloClient from 'utils/apolloClient';
import CategoriesQuery from 'assets/graphql/queries/Categories.graphql';
import CategoriesActivities from 'assets/graphql/queries/CategoriesActivities.graphql';
import ActivityCategoriesBanner from 'assets/graphql/queries/CategoriesActivitiesNew.graphql';

async function getCategories() {
  try {
    const { data } = await apolloClient.query({
      query: CategoriesQuery,
    });

    const categoriesArray = data.categories.map(category => {
      const { name, ...categoryData } = category;
      return { name, ...categoryData };
    });

    return categoriesArray;
  } catch (e) {
    throw new Error('Error fetching categories', e);
  }
}

async function getActivitiesCategories() {
  try {
    const { data } = await apolloClient.query({
      query: ActivityCategoriesBanner,
    });
    if (data.activity_categories_banner?.length !== 0) {
      const categoriesArray = data.activity_categories_banner.map(category => {
        const { name, ...data } = category;
        return { name, ...data };
      });

      const categoriesMap = new Map();

      categoriesArray.forEach(category => {
        const { name, ...data } = category;
        categoriesMap.set(name, data);
      });

      return categoriesMap;
    } else {
      return new Map();
    }
  } catch (e) {
    throw new Error('Error fetching activities categories', e);
  }
}

async function getFeaturedActivities() {
  try {
    const { data } = await apolloClient.query({
      query: CategoriesActivities,
      variables: {
        product_tag: 'featured',
        order: 'name',
      },
    });
    if (data.activity_categories?.length !== 0) {
      return data.activity_categories;
    } else {
      return [];
    }
  } catch (e) {
    throw new Error('Error fetching featured activities', e);
  }
}

export { getCategories, getFeaturedActivities, getActivitiesCategories };
