<template>
  <div
    class="notification"
    :class="circleSize"
    :style="`background-color: ${color}`"
  >
    <span class="content">{{ content }}</span>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({
    props: {
      color: {
        type: String,
        default: '#ff6c5f',
      },
      content: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'small',
      },
    },
  })
  export default class NotificationCircle extends Vue {
    get circleSize() {
      const sizes = {
        small: '--small',
        big: '--big',
      };
      return sizes[this.size];
    }
  }
</script>

<style lang="scss" scoped>
  .notification {
    border-radius: 50%;
    border: 1px solid white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      color: white;
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }

    &.--small {
      width: 10px;
      height: 10px;
    }

    &.--big {
      width: 20px;
      height: 20px;
    }
  }
</style>
