<template>
  <bb-modal
    @close="closeModal()"
    blockScroll
    :customPadding="customModalPadding"
  >
    <header-user-menu :user="user" @close="closeModal()" />
  </bb-modal>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  import BbModal from 'common/components/BbModal';
  import HeaderUserMenu from 'components/Header/HeaderUserMenu/HeaderUserMenu.vue';

  @Component({
    props: {
      showContent: {
        type: Boolean,
      },
      user: {
        type: Object,
      },
    },
    components: {
      BbModal,
      HeaderUserMenu,
    },
  })
  export default class MobileHeaderUserMenu extends Vue {
    customModalPadding = {
      mobile: '0 0 0 0',
      tablet: '0 0 0 0',
    };

    closeModal() {
      this.$emit('close');
    }
  }
</script>

<style scoped lang="scss">
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    bottom: 24px;
    width: auto;
    height: 48px;
    padding: 0 16px 0 16px;
    border: 1px solid $BLACK;
    border-radius: 8px;
  }

  .button-icon {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
</style>
