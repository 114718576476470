import getLastTld from 'common/services/utils/getLastTld';

// According to https://es.wikipedia.org/wiki/ISO_4217
const CURRENCY_CODES = {
  es: 'EUR',
  cl: 'CLP',
  ar: 'ARS',
  mx: 'MXN',
  pe: 'PEN',
  uy: 'UYU',
};

const getCurrencyCode = (showUSD = false) => {
  const tld = getLastTld();
  return showUSD ? 'USD' : CURRENCY_CODES[tld];
};

export default getCurrencyCode;
