<script setup>
  import { watch, ref, nextTick } from 'vue';

  const props = defineProps({
    initialAnimation: Boolean,
  });

  const timeoutId = ref();
  const svgEl = ref();

  watch(
    () => [props.initialAnimation, svgEl],
    async ([anim]) => {
      clearTimeout(timeoutId.value);
      await nextTick();
      if (anim) {
        svgEl.value.classList.add('bb-plus-user-icon-animation');
      }

      timeoutId.value = setTimeout(() => {
        svgEl.value.classList.remove('bb-plus-user-icon-animation');
      }, 6100);
    },
    { immediate: true },
  );
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    class="user-icon-svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="white" />
    <path
      d="M7.5 10.5417C7.5 13.188 9.6453 15.3333 12.2917 15.3333C14.938 15.3333 17.0833 13.188 17.0833 10.5417H15.8333C15.8333 12.4977 14.2477 14.0833 12.2917 14.0833C10.3357 14.0833 8.75 12.4977 8.75 10.5417H7.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2917 16.5833C10.3523 16.5833 8.56691 17.0734 7.24637 17.8987C5.93464 18.7186 5 19.9312 5 21.375C5 21.7202 5.27982 22 5.625 22H18.9583C19.3035 22 19.5833 21.7202 19.5833 21.375C19.5833 19.9312 18.6487 18.7186 17.337 17.8987C16.0164 17.0734 14.231 16.5833 12.2917 16.5833ZM16.6745 18.9587C17.5078 19.4796 18.0299 20.105 18.2346 20.75H6.34873C6.55338 20.105 7.0755 19.4796 7.90887 18.9587C9.00119 18.276 10.5491 17.8333 12.2917 17.8333C14.0342 17.8333 15.5821 18.276 16.6745 18.9587Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0416 2.41866L14.6002 4.4157L16.2991 3.18015C16.4892 3.04188 16.7408 3.02199 16.9503 3.12866C17.1598 3.23533 17.2917 3.45053 17.2917 3.68561V8.45833C17.2917 8.80351 17.0118 9.08333 16.6667 9.08333H7.91667C7.57149 9.08333 7.29167 8.80351 7.29167 8.45833V3.68561C7.29167 3.45053 7.42357 3.23533 7.63306 3.12866C7.84254 3.02199 8.09416 3.04188 8.28427 3.18015L9.98313 4.41568L11.5414 2.41869C11.8771 1.86045 12.7059 1.86044 13.0416 2.41866ZM12.2915 3.48928L10.5969 5.66101C10.3896 5.92665 10.0091 5.98016 9.73656 5.78198L8.54167 4.91296V7.83333H16.0417V4.91296L14.8468 5.78198C14.5743 5.98015 14.1938 5.92666 13.9865 5.66104L12.2915 3.48928Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
  .bb-plus-user-icon-animation {
    animation-name: bb-plus-user-icon-anim;
  }

  .user-icon-svg {
    animation-duration: 4s;
    animation-delay: 2s;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    fill: #81038e;
  }

  /*  @keyframes bb-plus-user-icon-anim {
    // Show tag
    0% {
      fill: #222222;
    }
    10% {
      fill: #5858da;
    }

    // keep tag

    // hide tag
    90% {
      fill: #5858da;
    }
    100% {
      fill: #5858da;
    }
  }
    */
</style>
