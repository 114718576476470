import Vue from 'vue';
import Component from 'vue-class-component';
import useIsMobile from 'common/composables/useIsMobile.js';
import useKustomerBotHelpers from 'common/composables/kustomerBotHelpers';

@Component({
  props: {
    maxWidth: {
      type: String,
      default: '',
    },
    limitWrapper: {
      type: String,
      default: '',
    },
    blockScroll: {
      type: Boolean,
      default: false,
    },
    smallPadding: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    newBigbox: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    isComparison: {
      type: Boolean,
      default: false,
    },
    centerCloseBtn: {
      type: Boolean,
      default: false,
    },
    customPadding: {
      type: Object, // padding string format: "0px 0px 0px 0px" (top, right, bottom, left)
      default: () => ({
        desktop: '',
        mobile: '',
      }),
      description:
        'receives an object of mobile version and desktop version paddings each property is a padding string format: "0px 0px 0px 0px" (top, right, bottom, left)',
    },
    crossColor: {
      type: String,
      default: 'black',
    },
    isMobileScrollable: {
      type: Boolean,
      default: false,
    },
    hideCloseBtn: {
      type: Boolean,
      default: false,
    },
    closeIconLarge: {
      type: Boolean,
      default: false,
    },
    smallModalBox: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isMobile, isTablet } = useIsMobile();
    const { hideKustomer, showKustomer } = useKustomerBotHelpers();

    return {
      hideKustomer,
      showKustomer,
      isMobile,
      isTablet,
    };
  },
})
export default class BbModalComponent extends Vue {
  bodyElement = document.body;
  bodyWidth = this.bodyElement.clientWidth;

  beforeMount() {
    this.focusedElBeforeOpen = document.activeElement;
  }

  mounted() {
    this.hideKustomer();
    this.bodyElement.classList.remove('--modalOpen');
    this.focusedElBeforeOpen.focus();
    window.removeEventListener('keydown', this.onKeyDown);
    if (this.blockScroll) this.removeScroll();
    if (this.newBigbox) window.addEventListener('resize', this.onResize);
  }

  beforeDestroy() {
    this.showKustomer();
    if (this.newBigbox) window.removeEventListener('resize', this.onResize);
    if (this.blockScroll) this.addScroll();
  }

  removeScroll() {
    /**
     * Don't change the order or it won't work
     */
    window.document.body.style.top = `-${window.scrollY}px`;
    window.document.body.style.position = 'fixed';
  }

  addScroll() {
    const scrollY = window.document.body.style.top;
    window.document.body.style.position = '';
    window.document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  }

  onResize() {
    this.bodyWidth = this.bodyElement.clientWidth;
  }

  modalClicked(event) {
    if (event.target === this.$refs.contentWrapper) {
      this.closeModal();
    }
  }

  closeModal() {
    this.$emit('close');
  }

  onKeyDown(event) {
    const KEY_ESC = 27;

    switch (event.keyCode) {
      case KEY_ESC:
        this.closeModal();
        break;
      default:
        break;
    }
  }

  /**
   * Checks the size of the body clientWidth in order to apply or not the styles of newBigbox close
   * Returns true if the clientWidth is greater than 768 ($screen-xs-max breakpoint) or maxWidth
   * + 72 pixels of space for the button overflow
   */
  get modalIsNotResized() {
    if (!this.newBigbox) return false;

    const breakpoint =
      this.maxWidth !== '' ? +this.maxWidth.replace('px', '') : 768;
    return this.bodyWidth >= breakpoint + 72;
  }
}
