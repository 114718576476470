import { defineStore } from 'pinia';
import apolloClient from 'utils/apolloClient';
import LoggedUserQuery from 'assets/graphql/queries/LoggedUser.graphql';
import AuthUserQuery from 'assets/graphql/queries/AuthUser.graphql';
import LoginUserMutation from 'assets/graphql/mutations/LoginUserMutation.graphql';
import LogoutUserMutation from 'assets/graphql/mutations/LogoutUserMutation.graphql';
import UpdateUserMutation from 'assets/graphql/mutations/UpdateUser.graphql';

export const useAccountStore = defineStore('account', {
  state: () => ({
    userLogged: null,
    userData: null,
    loading: true,
  }),
  getters: {
    getUserLogged: state => state.userLogged,
    getUserData: state => state.userData,
    getLoading: state => state.loading,
    getUserIsBigboxPlus: state => state.userData?.is_bigbox_plus,
  },
  actions: {
    // Queries
    async onUserData() {
      try {
        const { data } = await apolloClient.query({
          query: AuthUserQuery,
        });
        const { auth_user } = data;
        this.userData = auth_user;
        if (auth_user && auth_user.id) {
          localStorage.setItem('clarityUserId', auth_user.id);
        } else {
          localStorage.removeItem('clarityUserId');
        }
      } catch (err) {
        return err;
      }
    },
    async onUserStatus() {
      try {
        const { data } = await apolloClient.query({
          query: LoggedUserQuery,
        });
        const { logged_user } = data;
        this.userLogged = logged_user;
        if (!logged_user) {
          this.userData = null;
          localStorage.removeItem('clarityUserId');
        }
        this.loading = false;
      } catch (err) {
        return err;
      }
    },
    // Mutations
    async onLogin({ email, password }) {
      const res = await apolloClient.mutate({
        mutation: LoginUserMutation,
        variables: {
          email,
          password,
        },
      });
      return res;
    },
    async onLogout() {
      const res = await apolloClient.mutate({
        mutation: LogoutUserMutation,
      });
      const { logout } = res.data.logout;
      this.userLogged = !logout;
      this.userData = null;
      return res;
    },
    async onUpdateUser(user_data) {
      const res = await apolloClient.mutate({
        mutation: UpdateUserMutation,
        variables: {
          user_data,
        },
      });
      return res;
    },
  },
});
