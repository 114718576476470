import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAccountStore } from 'stores/account';

const useAccount = () => {
  const accountStore = useAccountStore();
  const { getUserLogged, getUserData, getLoading, getUserIsBigboxPlus } =
    storeToRefs(accountStore);

  const onUserStatus = async () => {
    const res = await accountStore.onUserStatus();
    return res;
  };
  const onUserData = async () => {
    const res = await accountStore.onUserData();
    return res;
  };

  const onLogin = async formLogin => {
    const res = await accountStore.onLogin(formLogin);
    return res;
  };
  const onLogout = async () => {
    const res = await accountStore.onLogout();
    return res;
  };
  const onUpdateUser = async user_data => {
    const res = await accountStore.onUpdateUser(user_data);
    return res;
  };

  return {
    onUserStatus,
    onUserData,
    onLogin,
    onLogout,
    onUpdateUser,
    getUserData: computed(() => getUserData.value),
    getUserLogged: computed(() => getUserLogged.value),
    getUserIsBigboxPlus: computed(() => getUserIsBigboxPlus.value),
    loading: computed(() => getLoading.value),
  };
};

export default useAccount;
