import Vue from 'vue';
import Component from 'vue-class-component';

import getLastTld from 'common/services/getLastTld';
import ReportFrontendErrorMutation from 'assets/graphql/mutations/ReportFrontendError.graphql';
import ApiKeys from 'assets/graphql/queries/ApiKeys.graphql';

@Component()
export default class Store extends Vue {
  notification = null;
  // These properties are set in ./createStore.js
  viewportWidth = null;
  host = '';
  features = [];
  darkenFullWebsite = false;
  darkenFullWebsiteMobile = false;
  isToolTipClosed = false;
  showPurchaseDetailButton = true

  get tld() {
    return getLastTld(this.host);
  }

  get isLatam() {
    return /^(cl|pe|mx)$/.test(this.tld);
  }

  get countryName() {
    switch (this.tld) {
      case 'ar':
        return 'Argentina';
      case 'cl':
        return 'Chile';
      case 'es':
        return 'España';
      case 'mx':
        return 'Mexico';
      case 'pe':
        return 'Perú';
      case 'uy':
        return 'Uruguay';
      default:
        return 'Argentina';
    }
  }

  get countryKeywords() {
    switch (this.tld) {
      case 'ar':
        return 'Argentina, Buenos Aires, CABA, Ciudad Autonoma de Buenos Aires';
      case 'pe':
        return 'Perú, Peru, Lima';
      case 'cl':
        return 'Chile, Santiago de Chile';
      case 'uy':
        return 'Uruguay, Montevideo';
      case 'mx':
        return 'México, México D.F. Mexico DF, Mexico, Distrito Federal, Ciudad de Mexico';
      case 'es':
        return 'España, Madrid';
      default:
        return 'Argentina, Buenos Aires, CABA, Ciudad Autonoma de Buenos Aires';
    }
  }

  get defaultKeywords() {
    return (
      'regalos, regalos originales, regalos empresariales, actividades, Bigbox, Big box, box, experiencias, empresas, corporativo, original, sorpresa, única, ' +
      `caja, ${this.countryKeywords},`
    );
  }

  get staticPagesKeywords() {
    return 'gastronomía, cena, almuerzo, desayuno, brunch, merienda, bienestar, aventura, estadía, estadia, estadias, restaurant, restaurante, restaurantes, hotel, hoteles, spas, spa,';
  }

  get hasTouch() {
    let hasTouchScreen = false;
    if ('maxTouchPoints' in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    return hasTouchScreen;
  }

  replaceState(state) {
    Object.assign(this.$data, state);
  }

  setViewportWidth(width) {
    this.viewportWidth = width;
  }

  setToolTipState(boolean) {
    this.isToolTipClosed = boolean;
  }

  setDarkenFullWebsite(state, mobile = false) {
    this.darkenFullWebsite = state;
    if (mobile) {
      this.darkenFullWebsiteMobile = state;
    }
  }

  fetchApiKeys() {
    return this.apolloClient
      .query({
        query: ApiKeys,
      })
      .then(res => res.data);
  }

  reportFrontendError(error) {
    this.apolloClient.mutate({
      mutation: ReportFrontendErrorMutation,
      variables: {
        name: error.name || 'GenericError',
        message: error.message || '',
        stack: error.stack || '',
      },
    });
  }
}
