<template>
  <div class="main-wrapper">
    <div class="selector">
      <slot name="mobileHeader" />
      <slot name="selector" />
    </div>
    <div v-if="showContent" class="content-wrapper">
      <slot name="mobileHeader" />
      <div
        v-if="showHeader"
        :class="isDesktop ? 'title-container-desktop' : 'mobile-header'"
      >
        <span
          v-if="!isDesktop"
          v-html="require('common/icons/Arrow.svg')"
          class="arrow-icon"
          @click="goBack()"
        />
        <span class="title-name-mobile">{{ contentTitle.title }}</span>
        <router-link
          v-if="contentTitle.seeAllRoute.routeName"
          class="see-all-text"
          :to="{
            name: contentTitle.seeAllRoute.routeName,
            query: contentTitle.seeAllRoute.routeQuery,
            params: contentTitle.seeAllRoute.routeParams,
          }"
          @click.native="setStoreFilter()"
          >Ver todo</router-link
        >
      </div>
      <slot name="mobileContent" />
      <slot name="content" />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  import useIsMobile from 'common/composables/useIsMobile.js';

  @Component({
    props: {
      showContent: {
        type: Boolean,
      },
      showMobileHeader: {
        type: Boolean,
        default: true,
      },
      contentTitle: {
        type: Object,
        default: () => ({
          title: null,
          seeAllRoute: {
            routeName: null, // example: 'Gastronomía'
            routeQuery: null, // example: { card: 0 }
            setStoreFilter: null,
            arg: null,
          },
        }),
      },
    },
    setup() {
      const { isDesktop } = useIsMobile();

      return {
        isDesktop,
      };
    },
  })
  export default class BaseHeaderMenuStructureComponent extends Vue {
    get hasContentTitle() {
      return this.contentTitle && this.contentTitle.title;
    }

    get showHeader() {
      return this.hasContentTitle && (this.isDesktop || this.showMobileHeader);
    }

    setStoreFilter() {
      if (!this.contentTitle.seeAllRoute.fn) return;
      this.contentTitle.seeAllRoute.fn(this.contentTitle.seeAllRoute.arg);
    }

    goBack() {
      this.$emit('goBack');
    }
  }
</script>

<style scoped lang="scss">
  .main-wrapper {
    max-width: 1222px;
    display: flex;

    @media (max-width: $screen-sm-max) {
      width: 100vw;
      /* stylelint-disable-next-line */
      max-height: 100svh;
      position: fixed;
      z-index: 3;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .selector {
      min-height: 100%;

      @media (max-width: $screen-sm-max) {
        width: 100vw;
        /* stylelint-disable-next-line */
        max-height: 100svh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .content-wrapper {
      min-width: 100%;
      display: flex;
      flex-direction: column;
      padding: 32px;

      @media (max-width: $screen-sm-max) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100vw;
        padding: 0;
        z-index: 2;
      }

      .title-container-desktop {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .title-name {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          color: $DARK-GRAY-2;
        }
      }

      .mobile-header {
        min-height: 80px;
        width: 100vw;
        padding: 16px;
        display: flex;
        align-items: center;

        .arrow-icon {
          height: 24px;
          width: 24px;
          margin-right: 16px;
        }

        .title-name-mobile {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
        }
      }

      .see-all-text {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        margin-left: 16px;
        text-decoration-line: underline;
        color: $DARK-GRAY-2;
      }
    }
  }
</style>
