<template>
  <div class="main-wrapper" v-if="content && content.length > 0">
    <BaseHeaderMenuStructure
      :showContent="isMobile ? !!selectedCategory : true"
      :contentTitle="contentTitle"
      @goBack="goBack"
    >
      <template v-if="isMobile" v-slot:selector>
        <div class="selector-wrapper">
          <div
            v-for="(category, index) in content"
            :key="index"
            @click="selectCategory(category.name)"
          >
            <span class="category-item">
              {{ category.name }}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:content>
        <div class="content-menu-wrapper" :class="{ '--region': !isArg }">
          <div
            v-for="(category, index) in menuContent"
            :key="index"
            class="content-col"
          >
            <span class="category-name">{{ category.name }}</span>
            <router-link
              clas="landing-name"
              v-for="(landing, index) in category.landings"
              :key="index"
              :to="`/landings/${landing.slug}/`"
              >{{ landing.name }}</router-link
            >
          </div>
        </div>
      </template>
    </BaseHeaderMenuStructure>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import BaseHeaderMenuStructure from 'components/Header/BaseHeaderMenuStructure/BaseHeaderMenuStructure.vue';
  import HeaderIconCard from 'components/Header/HeaderIconCard/HeaderIconCard.vue';

  import getLastTld from 'common/services/utils/getLastTld';
  import optimizeImage from 'common/utils/optimizeImage';
  import useIsMobile from 'common/composables/useIsMobile.js';

  @Component({
    components: {
      BaseHeaderMenuStructure,
      HeaderIconCard,
    },
    props: {
      content: {
        required: true,
        type: Array,
        default: () => [],
      },
      seeAllRoute: {
        type: Object,
      },
    },
    methods: {
      optimizeImage,
    },
    setup() {
      const { isDesktop } = useIsMobile();

      return {
        isDesktop,
      };
    },
  })
  export default class BaseHeaderMenuStructureComponent extends Vue {
    isArg = getLastTld() === 'ar';
    selectedCategory = null;

    contentTitle = {
      title: 'Ideas de regalo',
      seeAllRoute: {
        routeName: null,
      },
    };

    get menuContent() {
      return this.isMobile && this.selectedCategory
        ? [
            this.content.find(
              category => category.name === this.selectedCategory,
            ),
          ]
        : this.content;
    }

    selectCategory(categoryName) {
      this.selectedCategory = categoryName;
    }

    goBack() {
      if (this.selectedCategory) {
        this.selectedCategory = null;
      } else {
        this.$emit('closeOpenedMenu');
      }
    }
  }
</script>

<style scoped lang="scss">
  .main-wrapper {
    display: flex;
    width: 1222px;
    height: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: white;

    .mobile-header {
      min-height: 56px;
      width: 100vw;
      padding: 24px;
      display: flex;
      align-items: center;
      background-color: #ffffff;

      @media (max-width: $screen-sm-max) {
        padding: 0 16px;
      }

      @media (min-width: $screen-sm-min) {
        padding: 0 24px;
      }

      &.--hide {
        display: none;
      }

      .arrow-icon {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }

      .header-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
      }
    }

    .selector-wrapper {
      width: 100%;
      height: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;

      .category-item {
        padding: 4px 24px;
        height: 62px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        transition: 0.2s;
        color: $DARK-GRAY-2;
        background-color: white;
      }
    }

    .content-menu-wrapper {
      display: flex;
      gap: 24px;

      @media (max-width: $screen-sm-max) {
        padding: 24px 20px 20px 20px;
        height: 100%;
        overflow: scroll;
        background-color: #ffffff;
      }

      .content-col {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 212px;

        @media (max-width: $screen-sm-max) {
          width: 100%;
        }

        .category-name {
          font-family: Figtree;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }

        .landing-name {
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
</style>
