// example https://www.bigbox.com.ar/?utm_campaign=bigbox&utm_medium=referral&utm_source=ariel
import UtmTracking from 'common/graphql/mutations/UtmTracking.graphql';
import readCookie from 'common/services/readCookie';

export const utmHandler = async (to, apolloClient) => {
  const { utm_source = '', utm_campaign = '', utm_medium = '' } = to.query;

  let key = `${utm_source}-${utm_campaign}-${utm_medium}`;
  // check if exist in cookie
  let utmCookie = readCookie('utmIdObject');
  if (!utmCookie) {
    document.cookie = `utmIdObject={}; max-age=31536000`;
  }
  let utmObject = JSON.parse(utmCookie) || {};
  // if the utm tags are already saved in the cookie, return
  if (utmObject[key]) {
    return;
  }
  // if not, save the utm tags in the cookie
  let input = { utm_campaign, utm_medium, utm_source };
  let id = await getUtmId(input, apolloClient);
  saveUtmCookie(key, id);
};

const saveUtmCookie = (key, id) => {
  let utmCookie = readCookie('utmIdObject');
  let utmObject = JSON.parse(utmCookie) || {};
  utmObject[key] = id;

  let StringToSave = JSON.stringify(utmObject);

  document.cookie = `utmIdObject=${StringToSave};  max-age=31536000`;
};

const getUtmId = async (input, apolloClient) => {
  try {
    let result = await apolloClient.mutate({
      mutation: UtmTracking,
      variables: {
        input: input,
      },
    });
    if (result.data?.track_utm?.id) {
      return result.data.track_utm.id;
    }
  } catch (e) {
    return null;
  }
};

// export const sendOrderMutation = async (orderId, apolloClient) => {
//   let utmCookie = readCookie('utmIdObject');
//   let utmObject = JSON.parse(utmCookie);
//   let array = [];
//   for (let key in utmObject) {
//     array.push(utmObject[key]);
//   }
//
//   try {
//     let result = await apolloClient.mutate({
//       mutation: UtmOrderAssignment,
//       variables: {
//         input: { order: orderId, utms: array },
//       },
//     });
//     if (result.data.utm_order_assignment.errors) {
//       throw new Error(result.data.utm_order_assignment.errors[0].message);
//     } else {
//       //delete all utm tags from the cookie object
//       document.cookie = `utmIdObject={}; max-age=0`;
//     }
//   } catch (e) {
//     return null;
//   }
// };
